import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7b132fd6 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _02baf048 = () => interopDefault(import('..\\pages\\article\\index.vue' /* webpackChunkName: "pages/article/index" */))
const _514a9c0f = () => interopDefault(import('..\\pages\\demo.vue' /* webpackChunkName: "pages/demo" */))
const _81aacd08 = () => interopDefault(import('..\\pages\\member\\index.vue' /* webpackChunkName: "pages/member/index" */))
const _2613af6a = () => interopDefault(import('..\\pages\\article\\detail.vue' /* webpackChunkName: "pages/article/detail" */))
const _3e5ec1c6 = () => interopDefault(import('..\\pages\\goods\\brand.vue' /* webpackChunkName: "pages/goods/brand" */))
const _199b597a = () => interopDefault(import('..\\pages\\goods\\cart.vue' /* webpackChunkName: "pages/goods/cart" */))
const _1043ceae = () => interopDefault(import('..\\pages\\goods\\collage.vue' /* webpackChunkName: "pages/goods/collage" */))
const _a799e4aa = () => interopDefault(import('..\\pages\\goods\\detail.vue' /* webpackChunkName: "pages/goods/detail" */))
const _4b735dd2 = () => interopDefault(import('..\\pages\\goods\\detail2.vue' /* webpackChunkName: "pages/goods/detail2" */))
const _696c9ff8 = () => interopDefault(import('..\\pages\\goods\\list.vue' /* webpackChunkName: "pages/goods/list" */))
const _6dafee68 = () => interopDefault(import('..\\pages\\goods\\ranking.vue' /* webpackChunkName: "pages/goods/ranking" */))
const _e2b9d6b0 = () => interopDefault(import('..\\pages\\goods\\score.vue' /* webpackChunkName: "pages/goods/score" */))
const _80c250fc = () => interopDefault(import('..\\pages\\goods\\search.vue' /* webpackChunkName: "pages/goods/search" */))
const _65c30176 = () => interopDefault(import('..\\pages\\goods\\seckill.vue' /* webpackChunkName: "pages/goods/seckill" */))
const _04a2de50 = () => interopDefault(import('..\\pages\\goods\\shop.vue' /* webpackChunkName: "pages/goods/shop" */))
const _3bb32f1e = () => interopDefault(import('..\\pages\\member\\address.vue' /* webpackChunkName: "pages/member/address" */))
const _b08e5a6e = () => interopDefault(import('..\\pages\\member\\cancellation.vue' /* webpackChunkName: "pages/member/cancellation" */))
const _64a40664 = () => interopDefault(import('..\\pages\\member\\collection.vue' /* webpackChunkName: "pages/member/collection" */))
const _209672ec = () => interopDefault(import('..\\pages\\member\\coupon.vue' /* webpackChunkName: "pages/member/coupon" */))
const _4594c25f = () => interopDefault(import('..\\pages\\member\\evaluate.vue' /* webpackChunkName: "pages/member/evaluate" */))
const _fa6c622e = () => interopDefault(import('..\\pages\\member\\exchange.vue' /* webpackChunkName: "pages/member/exchange" */))
const _7173be06 = () => interopDefault(import('..\\pages\\member\\float.vue' /* webpackChunkName: "pages/member/float" */))
const _babf9b6e = () => interopDefault(import('..\\pages\\member\\introduce.vue' /* webpackChunkName: "pages/member/introduce" */))
const _50154c10 = () => interopDefault(import('..\\pages\\member\\order.vue' /* webpackChunkName: "pages/member/order" */))
const _4f5eff61 = () => interopDefault(import('..\\pages\\member\\password.vue' /* webpackChunkName: "pages/member/password" */))
const _1e271086 = () => interopDefault(import('..\\pages\\member\\personal.vue' /* webpackChunkName: "pages/member/personal" */))
const _74201a46 = () => interopDefault(import('..\\pages\\order\\pay.vue' /* webpackChunkName: "pages/order/pay" */))
const _08d3b36f = () => interopDefault(import('..\\pages\\order\\result.vue' /* webpackChunkName: "pages/order/result" */))
const _ce6c0d28 = () => interopDefault(import('..\\pages\\ucenter\\ucenter.vue' /* webpackChunkName: "pages/ucenter/ucenter" */))
const _0b3220b8 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\address.vue' /* webpackChunkName: "pages/ucenter/ucenter/address" */))
const _39015687 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\afterSale.vue' /* webpackChunkName: "pages/ucenter/ucenter/afterSale" */))
const _6d6b9e28 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\bank.vue' /* webpackChunkName: "pages/ucenter/ucenter/bank" */))
const _c26527a8 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\capital.vue' /* webpackChunkName: "pages/ucenter/ucenter/capital" */))
const _2b8d12ec = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\collection.vue' /* webpackChunkName: "pages/ucenter/ucenter/collection" */))
const _4aa974ba = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\comment.vue' /* webpackChunkName: "pages/ucenter/ucenter/comment" */))
const _1f05e612 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\coupon.vue' /* webpackChunkName: "pages/ucenter/ucenter/coupon" */))
const _b9addee2 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\exchange.vue' /* webpackChunkName: "pages/ucenter/ucenter/exchange" */))
const _b460317a = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\footprint.vue' /* webpackChunkName: "pages/ucenter/ucenter/footprint" */))
const _325318cc = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\info.vue' /* webpackChunkName: "pages/ucenter/ucenter/info" */))
const _488ad1b6 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\main.vue' /* webpackChunkName: "pages/ucenter/ucenter/main" */))
const _d4502bdc = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\order.vue' /* webpackChunkName: "pages/ucenter/ucenter/order" */))
const _6fbe4107 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\password.vue' /* webpackChunkName: "pages/ucenter/ucenter/password" */))
const _ae131eec = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\real.vue' /* webpackChunkName: "pages/ucenter/ucenter/real" */))
const _226c65ae = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\sign.vue' /* webpackChunkName: "pages/ucenter/ucenter/sign" */))
const _753d0661 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\vip.vue' /* webpackChunkName: "pages/ucenter/ucenter/vip" */))
const _670655e4 = () => interopDefault(import('..\\pages\\user\\forget.vue' /* webpackChunkName: "pages/user/forget" */))
const _d4ad647c = () => interopDefault(import('..\\pages\\user\\login.vue' /* webpackChunkName: "pages/user/login" */))
const _1f9813e0 = () => interopDefault(import('..\\pages\\user\\login2.vue' /* webpackChunkName: "pages/user/login2" */))
const _4d1ae1a3 = () => interopDefault(import('..\\pages\\user\\loginwx.vue' /* webpackChunkName: "pages/user/loginwx" */))
const _013e484c = () => interopDefault(import('..\\pages\\user\\register.vue' /* webpackChunkName: "pages/user/register" */))
const _464dc284 = () => interopDefault(import('..\\pages\\goods\\components\\magnifier.vue' /* webpackChunkName: "pages/goods/components/magnifier" */))
const _2346e438 = () => interopDefault(import('..\\pages\\goods\\components\\sp-aside-boxes.vue' /* webpackChunkName: "pages/goods/components/sp-aside-boxes" */))
const _016729f2 = () => interopDefault(import('..\\pages\\goods\\components\\sp-brand.vue' /* webpackChunkName: "pages/goods/components/sp-brand" */))
const _86935010 = () => interopDefault(import('..\\pages\\goods\\components\\sp-bread.vue' /* webpackChunkName: "pages/goods/components/sp-bread" */))
const _4d828b1a = () => interopDefault(import('..\\pages\\goods\\components\\sp-cart-guess.vue' /* webpackChunkName: "pages/goods/components/sp-cart-guess" */))
const _6db65d96 = () => interopDefault(import('..\\pages\\goods\\components\\sp-country.vue' /* webpackChunkName: "pages/goods/components/sp-country" */))
const _6a0ace85 = () => interopDefault(import('..\\pages\\goods\\components\\sp-head-filter.vue' /* webpackChunkName: "pages/goods/components/sp-head-filter" */))
const _cd7f2df2 = () => interopDefault(import('..\\pages\\goods\\components\\sp-list-filter.vue' /* webpackChunkName: "pages/goods/components/sp-list-filter" */))
const _37e08236 = () => interopDefault(import('..\\pages\\goods\\components\\sp-ranking.vue' /* webpackChunkName: "pages/goods/components/sp-ranking" */))
const _5aa5ef34 = () => interopDefault(import('..\\pages\\member\\components\\sp-your-likes.vue' /* webpackChunkName: "pages/member/components/sp-your-likes" */))
const _7fffec28 = () => interopDefault(import('..\\pages\\ucenter\\components\\sp-aside.vue' /* webpackChunkName: "pages/ucenter/components/sp-aside" */))
const _5008424a = () => interopDefault(import('..\\pages\\ucenter\\components\\sp-menu.vue' /* webpackChunkName: "pages/ucenter/components/sp-menu" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ara",
    component: _7b132fd6,
    name: "index___ara"
  }, {
    path: "/article",
    component: _02baf048,
    name: "article___zh"
  }, {
    path: "/cht",
    component: _7b132fd6,
    name: "index___cht"
  }, {
    path: "/demo",
    component: _514a9c0f,
    name: "demo___zh"
  }, {
    path: "/en",
    component: _7b132fd6,
    name: "index___en"
  }, {
    path: "/fra",
    component: _7b132fd6,
    name: "index___fra"
  }, {
    path: "/member",
    component: _81aacd08,
    name: "member___zh"
  }, {
    path: "/ara/article",
    component: _02baf048,
    name: "article___ara"
  }, {
    path: "/ara/demo",
    component: _514a9c0f,
    name: "demo___ara"
  }, {
    path: "/ara/member",
    component: _81aacd08,
    name: "member___ara"
  }, {
    path: "/article/detail",
    component: _2613af6a,
    name: "article-detail___zh"
  }, {
    path: "/cht/article",
    component: _02baf048,
    name: "article___cht"
  }, {
    path: "/cht/demo",
    component: _514a9c0f,
    name: "demo___cht"
  }, {
    path: "/cht/member",
    component: _81aacd08,
    name: "member___cht"
  }, {
    path: "/en/article",
    component: _02baf048,
    name: "article___en"
  }, {
    path: "/en/demo",
    component: _514a9c0f,
    name: "demo___en"
  }, {
    path: "/en/member",
    component: _81aacd08,
    name: "member___en"
  }, {
    path: "/fra/article",
    component: _02baf048,
    name: "article___fra"
  }, {
    path: "/fra/demo",
    component: _514a9c0f,
    name: "demo___fra"
  }, {
    path: "/fra/member",
    component: _81aacd08,
    name: "member___fra"
  }, {
    path: "/goods/brand",
    component: _3e5ec1c6,
    name: "goods-brand___zh"
  }, {
    path: "/goods/cart",
    component: _199b597a,
    name: "goods-cart___zh"
  }, {
    path: "/goods/collage",
    component: _1043ceae,
    name: "goods-collage___zh"
  }, {
    path: "/goods/detail",
    component: _a799e4aa,
    name: "goods-detail___zh"
  }, {
    path: "/goods/detail2",
    component: _4b735dd2,
    name: "goods-detail2___zh"
  }, {
    path: "/goods/list",
    component: _696c9ff8,
    name: "goods-list___zh"
  }, {
    path: "/goods/ranking",
    component: _6dafee68,
    name: "goods-ranking___zh"
  }, {
    path: "/goods/score",
    component: _e2b9d6b0,
    name: "goods-score___zh"
  }, {
    path: "/goods/search",
    component: _80c250fc,
    name: "goods-search___zh"
  }, {
    path: "/goods/seckill",
    component: _65c30176,
    name: "goods-seckill___zh"
  }, {
    path: "/goods/shop",
    component: _04a2de50,
    name: "goods-shop___zh"
  }, {
    path: "/member/address",
    component: _3bb32f1e,
    name: "member-address___zh"
  }, {
    path: "/member/cancellation",
    component: _b08e5a6e,
    name: "member-cancellation___zh"
  }, {
    path: "/member/collection",
    component: _64a40664,
    name: "member-collection___zh"
  }, {
    path: "/member/coupon",
    component: _209672ec,
    name: "member-coupon___zh"
  }, {
    path: "/member/evaluate",
    component: _4594c25f,
    name: "member-evaluate___zh"
  }, {
    path: "/member/exchange",
    component: _fa6c622e,
    name: "member-exchange___zh"
  }, {
    path: "/member/float",
    component: _7173be06,
    name: "member-float___zh"
  }, {
    path: "/member/introduce",
    component: _babf9b6e,
    name: "member-introduce___zh"
  }, {
    path: "/member/order",
    component: _50154c10,
    name: "member-order___zh"
  }, {
    path: "/member/password",
    component: _4f5eff61,
    name: "member-password___zh"
  }, {
    path: "/member/personal",
    component: _1e271086,
    name: "member-personal___zh"
  }, {
    path: "/order/pay",
    component: _74201a46,
    name: "order-pay___zh"
  }, {
    path: "/order/result",
    component: _08d3b36f,
    name: "order-result___zh"
  }, {
    path: "/ucenter/ucenter",
    component: _ce6c0d28,
    name: "ucenter-ucenter___zh",
    children: [{
      path: "address",
      component: _0b3220b8,
      name: "ucenter-ucenter-address___zh"
    }, {
      path: "afterSale",
      component: _39015687,
      name: "ucenter-ucenter-afterSale___zh"
    }, {
      path: "bank",
      component: _6d6b9e28,
      name: "ucenter-ucenter-bank___zh"
    }, {
      path: "capital",
      component: _c26527a8,
      name: "ucenter-ucenter-capital___zh"
    }, {
      path: "collection",
      component: _2b8d12ec,
      name: "ucenter-ucenter-collection___zh"
    }, {
      path: "comment",
      component: _4aa974ba,
      name: "ucenter-ucenter-comment___zh"
    }, {
      path: "coupon",
      component: _1f05e612,
      name: "ucenter-ucenter-coupon___zh"
    }, {
      path: "exchange",
      component: _b9addee2,
      name: "ucenter-ucenter-exchange___zh"
    }, {
      path: "footprint",
      component: _b460317a,
      name: "ucenter-ucenter-footprint___zh"
    }, {
      path: "info",
      component: _325318cc,
      name: "ucenter-ucenter-info___zh"
    }, {
      path: "main",
      component: _488ad1b6,
      name: "ucenter-ucenter-main___zh"
    }, {
      path: "order",
      component: _d4502bdc,
      name: "ucenter-ucenter-order___zh"
    }, {
      path: "password",
      component: _6fbe4107,
      name: "ucenter-ucenter-password___zh"
    }, {
      path: "real",
      component: _ae131eec,
      name: "ucenter-ucenter-real___zh"
    }, {
      path: "sign",
      component: _226c65ae,
      name: "ucenter-ucenter-sign___zh"
    }, {
      path: "vip",
      component: _753d0661,
      name: "ucenter-ucenter-vip___zh"
    }]
  }, {
    path: "/user/forget",
    component: _670655e4,
    name: "user-forget___zh"
  }, {
    path: "/user/login",
    component: _d4ad647c,
    name: "user-login___zh"
  }, {
    path: "/user/login2",
    component: _1f9813e0,
    name: "user-login2___zh"
  }, {
    path: "/user/loginwx",
    component: _4d1ae1a3,
    name: "user-loginwx___zh"
  }, {
    path: "/user/register",
    component: _013e484c,
    name: "user-register___zh"
  }, {
    path: "/ara/article/detail",
    component: _2613af6a,
    name: "article-detail___ara"
  }, {
    path: "/ara/goods/brand",
    component: _3e5ec1c6,
    name: "goods-brand___ara"
  }, {
    path: "/ara/goods/cart",
    component: _199b597a,
    name: "goods-cart___ara"
  }, {
    path: "/ara/goods/collage",
    component: _1043ceae,
    name: "goods-collage___ara"
  }, {
    path: "/ara/goods/detail",
    component: _a799e4aa,
    name: "goods-detail___ara"
  }, {
    path: "/ara/goods/detail2",
    component: _4b735dd2,
    name: "goods-detail2___ara"
  }, {
    path: "/ara/goods/list",
    component: _696c9ff8,
    name: "goods-list___ara"
  }, {
    path: "/ara/goods/ranking",
    component: _6dafee68,
    name: "goods-ranking___ara"
  }, {
    path: "/ara/goods/score",
    component: _e2b9d6b0,
    name: "goods-score___ara"
  }, {
    path: "/ara/goods/search",
    component: _80c250fc,
    name: "goods-search___ara"
  }, {
    path: "/ara/goods/seckill",
    component: _65c30176,
    name: "goods-seckill___ara"
  }, {
    path: "/ara/goods/shop",
    component: _04a2de50,
    name: "goods-shop___ara"
  }, {
    path: "/ara/member/address",
    component: _3bb32f1e,
    name: "member-address___ara"
  }, {
    path: "/ara/member/cancellation",
    component: _b08e5a6e,
    name: "member-cancellation___ara"
  }, {
    path: "/ara/member/collection",
    component: _64a40664,
    name: "member-collection___ara"
  }, {
    path: "/ara/member/coupon",
    component: _209672ec,
    name: "member-coupon___ara"
  }, {
    path: "/ara/member/evaluate",
    component: _4594c25f,
    name: "member-evaluate___ara"
  }, {
    path: "/ara/member/exchange",
    component: _fa6c622e,
    name: "member-exchange___ara"
  }, {
    path: "/ara/member/float",
    component: _7173be06,
    name: "member-float___ara"
  }, {
    path: "/ara/member/introduce",
    component: _babf9b6e,
    name: "member-introduce___ara"
  }, {
    path: "/ara/member/order",
    component: _50154c10,
    name: "member-order___ara"
  }, {
    path: "/ara/member/password",
    component: _4f5eff61,
    name: "member-password___ara"
  }, {
    path: "/ara/member/personal",
    component: _1e271086,
    name: "member-personal___ara"
  }, {
    path: "/ara/order/pay",
    component: _74201a46,
    name: "order-pay___ara"
  }, {
    path: "/ara/order/result",
    component: _08d3b36f,
    name: "order-result___ara"
  }, {
    path: "/ara/ucenter/ucenter",
    component: _ce6c0d28,
    name: "ucenter-ucenter___ara",
    children: [{
      path: "address",
      component: _0b3220b8,
      name: "ucenter-ucenter-address___ara"
    }, {
      path: "afterSale",
      component: _39015687,
      name: "ucenter-ucenter-afterSale___ara"
    }, {
      path: "bank",
      component: _6d6b9e28,
      name: "ucenter-ucenter-bank___ara"
    }, {
      path: "capital",
      component: _c26527a8,
      name: "ucenter-ucenter-capital___ara"
    }, {
      path: "collection",
      component: _2b8d12ec,
      name: "ucenter-ucenter-collection___ara"
    }, {
      path: "comment",
      component: _4aa974ba,
      name: "ucenter-ucenter-comment___ara"
    }, {
      path: "coupon",
      component: _1f05e612,
      name: "ucenter-ucenter-coupon___ara"
    }, {
      path: "exchange",
      component: _b9addee2,
      name: "ucenter-ucenter-exchange___ara"
    }, {
      path: "footprint",
      component: _b460317a,
      name: "ucenter-ucenter-footprint___ara"
    }, {
      path: "info",
      component: _325318cc,
      name: "ucenter-ucenter-info___ara"
    }, {
      path: "main",
      component: _488ad1b6,
      name: "ucenter-ucenter-main___ara"
    }, {
      path: "order",
      component: _d4502bdc,
      name: "ucenter-ucenter-order___ara"
    }, {
      path: "password",
      component: _6fbe4107,
      name: "ucenter-ucenter-password___ara"
    }, {
      path: "real",
      component: _ae131eec,
      name: "ucenter-ucenter-real___ara"
    }, {
      path: "sign",
      component: _226c65ae,
      name: "ucenter-ucenter-sign___ara"
    }, {
      path: "vip",
      component: _753d0661,
      name: "ucenter-ucenter-vip___ara"
    }]
  }, {
    path: "/ara/user/forget",
    component: _670655e4,
    name: "user-forget___ara"
  }, {
    path: "/ara/user/login",
    component: _d4ad647c,
    name: "user-login___ara"
  }, {
    path: "/ara/user/login2",
    component: _1f9813e0,
    name: "user-login2___ara"
  }, {
    path: "/ara/user/loginwx",
    component: _4d1ae1a3,
    name: "user-loginwx___ara"
  }, {
    path: "/ara/user/register",
    component: _013e484c,
    name: "user-register___ara"
  }, {
    path: "/cht/article/detail",
    component: _2613af6a,
    name: "article-detail___cht"
  }, {
    path: "/cht/goods/brand",
    component: _3e5ec1c6,
    name: "goods-brand___cht"
  }, {
    path: "/cht/goods/cart",
    component: _199b597a,
    name: "goods-cart___cht"
  }, {
    path: "/cht/goods/collage",
    component: _1043ceae,
    name: "goods-collage___cht"
  }, {
    path: "/cht/goods/detail",
    component: _a799e4aa,
    name: "goods-detail___cht"
  }, {
    path: "/cht/goods/detail2",
    component: _4b735dd2,
    name: "goods-detail2___cht"
  }, {
    path: "/cht/goods/list",
    component: _696c9ff8,
    name: "goods-list___cht"
  }, {
    path: "/cht/goods/ranking",
    component: _6dafee68,
    name: "goods-ranking___cht"
  }, {
    path: "/cht/goods/score",
    component: _e2b9d6b0,
    name: "goods-score___cht"
  }, {
    path: "/cht/goods/search",
    component: _80c250fc,
    name: "goods-search___cht"
  }, {
    path: "/cht/goods/seckill",
    component: _65c30176,
    name: "goods-seckill___cht"
  }, {
    path: "/cht/goods/shop",
    component: _04a2de50,
    name: "goods-shop___cht"
  }, {
    path: "/cht/member/address",
    component: _3bb32f1e,
    name: "member-address___cht"
  }, {
    path: "/cht/member/cancellation",
    component: _b08e5a6e,
    name: "member-cancellation___cht"
  }, {
    path: "/cht/member/collection",
    component: _64a40664,
    name: "member-collection___cht"
  }, {
    path: "/cht/member/coupon",
    component: _209672ec,
    name: "member-coupon___cht"
  }, {
    path: "/cht/member/evaluate",
    component: _4594c25f,
    name: "member-evaluate___cht"
  }, {
    path: "/cht/member/exchange",
    component: _fa6c622e,
    name: "member-exchange___cht"
  }, {
    path: "/cht/member/float",
    component: _7173be06,
    name: "member-float___cht"
  }, {
    path: "/cht/member/introduce",
    component: _babf9b6e,
    name: "member-introduce___cht"
  }, {
    path: "/cht/member/order",
    component: _50154c10,
    name: "member-order___cht"
  }, {
    path: "/cht/member/password",
    component: _4f5eff61,
    name: "member-password___cht"
  }, {
    path: "/cht/member/personal",
    component: _1e271086,
    name: "member-personal___cht"
  }, {
    path: "/cht/order/pay",
    component: _74201a46,
    name: "order-pay___cht"
  }, {
    path: "/cht/order/result",
    component: _08d3b36f,
    name: "order-result___cht"
  }, {
    path: "/cht/ucenter/ucenter",
    component: _ce6c0d28,
    name: "ucenter-ucenter___cht",
    children: [{
      path: "address",
      component: _0b3220b8,
      name: "ucenter-ucenter-address___cht"
    }, {
      path: "afterSale",
      component: _39015687,
      name: "ucenter-ucenter-afterSale___cht"
    }, {
      path: "bank",
      component: _6d6b9e28,
      name: "ucenter-ucenter-bank___cht"
    }, {
      path: "capital",
      component: _c26527a8,
      name: "ucenter-ucenter-capital___cht"
    }, {
      path: "collection",
      component: _2b8d12ec,
      name: "ucenter-ucenter-collection___cht"
    }, {
      path: "comment",
      component: _4aa974ba,
      name: "ucenter-ucenter-comment___cht"
    }, {
      path: "coupon",
      component: _1f05e612,
      name: "ucenter-ucenter-coupon___cht"
    }, {
      path: "exchange",
      component: _b9addee2,
      name: "ucenter-ucenter-exchange___cht"
    }, {
      path: "footprint",
      component: _b460317a,
      name: "ucenter-ucenter-footprint___cht"
    }, {
      path: "info",
      component: _325318cc,
      name: "ucenter-ucenter-info___cht"
    }, {
      path: "main",
      component: _488ad1b6,
      name: "ucenter-ucenter-main___cht"
    }, {
      path: "order",
      component: _d4502bdc,
      name: "ucenter-ucenter-order___cht"
    }, {
      path: "password",
      component: _6fbe4107,
      name: "ucenter-ucenter-password___cht"
    }, {
      path: "real",
      component: _ae131eec,
      name: "ucenter-ucenter-real___cht"
    }, {
      path: "sign",
      component: _226c65ae,
      name: "ucenter-ucenter-sign___cht"
    }, {
      path: "vip",
      component: _753d0661,
      name: "ucenter-ucenter-vip___cht"
    }]
  }, {
    path: "/cht/user/forget",
    component: _670655e4,
    name: "user-forget___cht"
  }, {
    path: "/cht/user/login",
    component: _d4ad647c,
    name: "user-login___cht"
  }, {
    path: "/cht/user/login2",
    component: _1f9813e0,
    name: "user-login2___cht"
  }, {
    path: "/cht/user/loginwx",
    component: _4d1ae1a3,
    name: "user-loginwx___cht"
  }, {
    path: "/cht/user/register",
    component: _013e484c,
    name: "user-register___cht"
  }, {
    path: "/en/article/detail",
    component: _2613af6a,
    name: "article-detail___en"
  }, {
    path: "/en/goods/brand",
    component: _3e5ec1c6,
    name: "goods-brand___en"
  }, {
    path: "/en/goods/cart",
    component: _199b597a,
    name: "goods-cart___en"
  }, {
    path: "/en/goods/collage",
    component: _1043ceae,
    name: "goods-collage___en"
  }, {
    path: "/en/goods/detail",
    component: _a799e4aa,
    name: "goods-detail___en"
  }, {
    path: "/en/goods/detail2",
    component: _4b735dd2,
    name: "goods-detail2___en"
  }, {
    path: "/en/goods/list",
    component: _696c9ff8,
    name: "goods-list___en"
  }, {
    path: "/en/goods/ranking",
    component: _6dafee68,
    name: "goods-ranking___en"
  }, {
    path: "/en/goods/score",
    component: _e2b9d6b0,
    name: "goods-score___en"
  }, {
    path: "/en/goods/search",
    component: _80c250fc,
    name: "goods-search___en"
  }, {
    path: "/en/goods/seckill",
    component: _65c30176,
    name: "goods-seckill___en"
  }, {
    path: "/en/goods/shop",
    component: _04a2de50,
    name: "goods-shop___en"
  }, {
    path: "/en/member/address",
    component: _3bb32f1e,
    name: "member-address___en"
  }, {
    path: "/en/member/cancellation",
    component: _b08e5a6e,
    name: "member-cancellation___en"
  }, {
    path: "/en/member/collection",
    component: _64a40664,
    name: "member-collection___en"
  }, {
    path: "/en/member/coupon",
    component: _209672ec,
    name: "member-coupon___en"
  }, {
    path: "/en/member/evaluate",
    component: _4594c25f,
    name: "member-evaluate___en"
  }, {
    path: "/en/member/exchange",
    component: _fa6c622e,
    name: "member-exchange___en"
  }, {
    path: "/en/member/float",
    component: _7173be06,
    name: "member-float___en"
  }, {
    path: "/en/member/introduce",
    component: _babf9b6e,
    name: "member-introduce___en"
  }, {
    path: "/en/member/order",
    component: _50154c10,
    name: "member-order___en"
  }, {
    path: "/en/member/password",
    component: _4f5eff61,
    name: "member-password___en"
  }, {
    path: "/en/member/personal",
    component: _1e271086,
    name: "member-personal___en"
  }, {
    path: "/en/order/pay",
    component: _74201a46,
    name: "order-pay___en"
  }, {
    path: "/en/order/result",
    component: _08d3b36f,
    name: "order-result___en"
  }, {
    path: "/en/ucenter/ucenter",
    component: _ce6c0d28,
    name: "ucenter-ucenter___en",
    children: [{
      path: "address",
      component: _0b3220b8,
      name: "ucenter-ucenter-address___en"
    }, {
      path: "afterSale",
      component: _39015687,
      name: "ucenter-ucenter-afterSale___en"
    }, {
      path: "bank",
      component: _6d6b9e28,
      name: "ucenter-ucenter-bank___en"
    }, {
      path: "capital",
      component: _c26527a8,
      name: "ucenter-ucenter-capital___en"
    }, {
      path: "collection",
      component: _2b8d12ec,
      name: "ucenter-ucenter-collection___en"
    }, {
      path: "comment",
      component: _4aa974ba,
      name: "ucenter-ucenter-comment___en"
    }, {
      path: "coupon",
      component: _1f05e612,
      name: "ucenter-ucenter-coupon___en"
    }, {
      path: "exchange",
      component: _b9addee2,
      name: "ucenter-ucenter-exchange___en"
    }, {
      path: "footprint",
      component: _b460317a,
      name: "ucenter-ucenter-footprint___en"
    }, {
      path: "info",
      component: _325318cc,
      name: "ucenter-ucenter-info___en"
    }, {
      path: "main",
      component: _488ad1b6,
      name: "ucenter-ucenter-main___en"
    }, {
      path: "order",
      component: _d4502bdc,
      name: "ucenter-ucenter-order___en"
    }, {
      path: "password",
      component: _6fbe4107,
      name: "ucenter-ucenter-password___en"
    }, {
      path: "real",
      component: _ae131eec,
      name: "ucenter-ucenter-real___en"
    }, {
      path: "sign",
      component: _226c65ae,
      name: "ucenter-ucenter-sign___en"
    }, {
      path: "vip",
      component: _753d0661,
      name: "ucenter-ucenter-vip___en"
    }]
  }, {
    path: "/en/user/forget",
    component: _670655e4,
    name: "user-forget___en"
  }, {
    path: "/en/user/login",
    component: _d4ad647c,
    name: "user-login___en"
  }, {
    path: "/en/user/login2",
    component: _1f9813e0,
    name: "user-login2___en"
  }, {
    path: "/en/user/loginwx",
    component: _4d1ae1a3,
    name: "user-loginwx___en"
  }, {
    path: "/en/user/register",
    component: _013e484c,
    name: "user-register___en"
  }, {
    path: "/fra/article/detail",
    component: _2613af6a,
    name: "article-detail___fra"
  }, {
    path: "/fra/goods/brand",
    component: _3e5ec1c6,
    name: "goods-brand___fra"
  }, {
    path: "/fra/goods/cart",
    component: _199b597a,
    name: "goods-cart___fra"
  }, {
    path: "/fra/goods/collage",
    component: _1043ceae,
    name: "goods-collage___fra"
  }, {
    path: "/fra/goods/detail",
    component: _a799e4aa,
    name: "goods-detail___fra"
  }, {
    path: "/fra/goods/detail2",
    component: _4b735dd2,
    name: "goods-detail2___fra"
  }, {
    path: "/fra/goods/list",
    component: _696c9ff8,
    name: "goods-list___fra"
  }, {
    path: "/fra/goods/ranking",
    component: _6dafee68,
    name: "goods-ranking___fra"
  }, {
    path: "/fra/goods/score",
    component: _e2b9d6b0,
    name: "goods-score___fra"
  }, {
    path: "/fra/goods/search",
    component: _80c250fc,
    name: "goods-search___fra"
  }, {
    path: "/fra/goods/seckill",
    component: _65c30176,
    name: "goods-seckill___fra"
  }, {
    path: "/fra/goods/shop",
    component: _04a2de50,
    name: "goods-shop___fra"
  }, {
    path: "/fra/member/address",
    component: _3bb32f1e,
    name: "member-address___fra"
  }, {
    path: "/fra/member/cancellation",
    component: _b08e5a6e,
    name: "member-cancellation___fra"
  }, {
    path: "/fra/member/collection",
    component: _64a40664,
    name: "member-collection___fra"
  }, {
    path: "/fra/member/coupon",
    component: _209672ec,
    name: "member-coupon___fra"
  }, {
    path: "/fra/member/evaluate",
    component: _4594c25f,
    name: "member-evaluate___fra"
  }, {
    path: "/fra/member/exchange",
    component: _fa6c622e,
    name: "member-exchange___fra"
  }, {
    path: "/fra/member/float",
    component: _7173be06,
    name: "member-float___fra"
  }, {
    path: "/fra/member/introduce",
    component: _babf9b6e,
    name: "member-introduce___fra"
  }, {
    path: "/fra/member/order",
    component: _50154c10,
    name: "member-order___fra"
  }, {
    path: "/fra/member/password",
    component: _4f5eff61,
    name: "member-password___fra"
  }, {
    path: "/fra/member/personal",
    component: _1e271086,
    name: "member-personal___fra"
  }, {
    path: "/fra/order/pay",
    component: _74201a46,
    name: "order-pay___fra"
  }, {
    path: "/fra/order/result",
    component: _08d3b36f,
    name: "order-result___fra"
  }, {
    path: "/fra/ucenter/ucenter",
    component: _ce6c0d28,
    name: "ucenter-ucenter___fra",
    children: [{
      path: "address",
      component: _0b3220b8,
      name: "ucenter-ucenter-address___fra"
    }, {
      path: "afterSale",
      component: _39015687,
      name: "ucenter-ucenter-afterSale___fra"
    }, {
      path: "bank",
      component: _6d6b9e28,
      name: "ucenter-ucenter-bank___fra"
    }, {
      path: "capital",
      component: _c26527a8,
      name: "ucenter-ucenter-capital___fra"
    }, {
      path: "collection",
      component: _2b8d12ec,
      name: "ucenter-ucenter-collection___fra"
    }, {
      path: "comment",
      component: _4aa974ba,
      name: "ucenter-ucenter-comment___fra"
    }, {
      path: "coupon",
      component: _1f05e612,
      name: "ucenter-ucenter-coupon___fra"
    }, {
      path: "exchange",
      component: _b9addee2,
      name: "ucenter-ucenter-exchange___fra"
    }, {
      path: "footprint",
      component: _b460317a,
      name: "ucenter-ucenter-footprint___fra"
    }, {
      path: "info",
      component: _325318cc,
      name: "ucenter-ucenter-info___fra"
    }, {
      path: "main",
      component: _488ad1b6,
      name: "ucenter-ucenter-main___fra"
    }, {
      path: "order",
      component: _d4502bdc,
      name: "ucenter-ucenter-order___fra"
    }, {
      path: "password",
      component: _6fbe4107,
      name: "ucenter-ucenter-password___fra"
    }, {
      path: "real",
      component: _ae131eec,
      name: "ucenter-ucenter-real___fra"
    }, {
      path: "sign",
      component: _226c65ae,
      name: "ucenter-ucenter-sign___fra"
    }, {
      path: "vip",
      component: _753d0661,
      name: "ucenter-ucenter-vip___fra"
    }]
  }, {
    path: "/fra/user/forget",
    component: _670655e4,
    name: "user-forget___fra"
  }, {
    path: "/fra/user/login",
    component: _d4ad647c,
    name: "user-login___fra"
  }, {
    path: "/fra/user/login2",
    component: _1f9813e0,
    name: "user-login2___fra"
  }, {
    path: "/fra/user/loginwx",
    component: _4d1ae1a3,
    name: "user-loginwx___fra"
  }, {
    path: "/fra/user/register",
    component: _013e484c,
    name: "user-register___fra"
  }, {
    path: "/goods/components/magnifier",
    component: _464dc284,
    name: "goods-components-magnifier___zh"
  }, {
    path: "/goods/components/sp-aside-boxes",
    component: _2346e438,
    name: "goods-components-sp-aside-boxes___zh"
  }, {
    path: "/goods/components/sp-brand",
    component: _016729f2,
    name: "goods-components-sp-brand___zh"
  }, {
    path: "/goods/components/sp-bread",
    component: _86935010,
    name: "goods-components-sp-bread___zh"
  }, {
    path: "/goods/components/sp-cart-guess",
    component: _4d828b1a,
    name: "goods-components-sp-cart-guess___zh"
  }, {
    path: "/goods/components/sp-country",
    component: _6db65d96,
    name: "goods-components-sp-country___zh"
  }, {
    path: "/goods/components/sp-head-filter",
    component: _6a0ace85,
    name: "goods-components-sp-head-filter___zh"
  }, {
    path: "/goods/components/sp-list-filter",
    component: _cd7f2df2,
    name: "goods-components-sp-list-filter___zh"
  }, {
    path: "/goods/components/sp-ranking",
    component: _37e08236,
    name: "goods-components-sp-ranking___zh"
  }, {
    path: "/member/components/sp-your-likes",
    component: _5aa5ef34,
    name: "member-components-sp-your-likes___zh"
  }, {
    path: "/ucenter/components/sp-aside",
    component: _7fffec28,
    name: "ucenter-components-sp-aside___zh"
  }, {
    path: "/ucenter/components/sp-menu",
    component: _5008424a,
    name: "ucenter-components-sp-menu___zh"
  }, {
    path: "/ara/goods/components/magnifier",
    component: _464dc284,
    name: "goods-components-magnifier___ara"
  }, {
    path: "/ara/goods/components/sp-aside-boxes",
    component: _2346e438,
    name: "goods-components-sp-aside-boxes___ara"
  }, {
    path: "/ara/goods/components/sp-brand",
    component: _016729f2,
    name: "goods-components-sp-brand___ara"
  }, {
    path: "/ara/goods/components/sp-bread",
    component: _86935010,
    name: "goods-components-sp-bread___ara"
  }, {
    path: "/ara/goods/components/sp-cart-guess",
    component: _4d828b1a,
    name: "goods-components-sp-cart-guess___ara"
  }, {
    path: "/ara/goods/components/sp-country",
    component: _6db65d96,
    name: "goods-components-sp-country___ara"
  }, {
    path: "/ara/goods/components/sp-head-filter",
    component: _6a0ace85,
    name: "goods-components-sp-head-filter___ara"
  }, {
    path: "/ara/goods/components/sp-list-filter",
    component: _cd7f2df2,
    name: "goods-components-sp-list-filter___ara"
  }, {
    path: "/ara/goods/components/sp-ranking",
    component: _37e08236,
    name: "goods-components-sp-ranking___ara"
  }, {
    path: "/ara/member/components/sp-your-likes",
    component: _5aa5ef34,
    name: "member-components-sp-your-likes___ara"
  }, {
    path: "/ara/ucenter/components/sp-aside",
    component: _7fffec28,
    name: "ucenter-components-sp-aside___ara"
  }, {
    path: "/ara/ucenter/components/sp-menu",
    component: _5008424a,
    name: "ucenter-components-sp-menu___ara"
  }, {
    path: "/cht/goods/components/magnifier",
    component: _464dc284,
    name: "goods-components-magnifier___cht"
  }, {
    path: "/cht/goods/components/sp-aside-boxes",
    component: _2346e438,
    name: "goods-components-sp-aside-boxes___cht"
  }, {
    path: "/cht/goods/components/sp-brand",
    component: _016729f2,
    name: "goods-components-sp-brand___cht"
  }, {
    path: "/cht/goods/components/sp-bread",
    component: _86935010,
    name: "goods-components-sp-bread___cht"
  }, {
    path: "/cht/goods/components/sp-cart-guess",
    component: _4d828b1a,
    name: "goods-components-sp-cart-guess___cht"
  }, {
    path: "/cht/goods/components/sp-country",
    component: _6db65d96,
    name: "goods-components-sp-country___cht"
  }, {
    path: "/cht/goods/components/sp-head-filter",
    component: _6a0ace85,
    name: "goods-components-sp-head-filter___cht"
  }, {
    path: "/cht/goods/components/sp-list-filter",
    component: _cd7f2df2,
    name: "goods-components-sp-list-filter___cht"
  }, {
    path: "/cht/goods/components/sp-ranking",
    component: _37e08236,
    name: "goods-components-sp-ranking___cht"
  }, {
    path: "/cht/member/components/sp-your-likes",
    component: _5aa5ef34,
    name: "member-components-sp-your-likes___cht"
  }, {
    path: "/cht/ucenter/components/sp-aside",
    component: _7fffec28,
    name: "ucenter-components-sp-aside___cht"
  }, {
    path: "/cht/ucenter/components/sp-menu",
    component: _5008424a,
    name: "ucenter-components-sp-menu___cht"
  }, {
    path: "/en/goods/components/magnifier",
    component: _464dc284,
    name: "goods-components-magnifier___en"
  }, {
    path: "/en/goods/components/sp-aside-boxes",
    component: _2346e438,
    name: "goods-components-sp-aside-boxes___en"
  }, {
    path: "/en/goods/components/sp-brand",
    component: _016729f2,
    name: "goods-components-sp-brand___en"
  }, {
    path: "/en/goods/components/sp-bread",
    component: _86935010,
    name: "goods-components-sp-bread___en"
  }, {
    path: "/en/goods/components/sp-cart-guess",
    component: _4d828b1a,
    name: "goods-components-sp-cart-guess___en"
  }, {
    path: "/en/goods/components/sp-country",
    component: _6db65d96,
    name: "goods-components-sp-country___en"
  }, {
    path: "/en/goods/components/sp-head-filter",
    component: _6a0ace85,
    name: "goods-components-sp-head-filter___en"
  }, {
    path: "/en/goods/components/sp-list-filter",
    component: _cd7f2df2,
    name: "goods-components-sp-list-filter___en"
  }, {
    path: "/en/goods/components/sp-ranking",
    component: _37e08236,
    name: "goods-components-sp-ranking___en"
  }, {
    path: "/en/member/components/sp-your-likes",
    component: _5aa5ef34,
    name: "member-components-sp-your-likes___en"
  }, {
    path: "/en/ucenter/components/sp-aside",
    component: _7fffec28,
    name: "ucenter-components-sp-aside___en"
  }, {
    path: "/en/ucenter/components/sp-menu",
    component: _5008424a,
    name: "ucenter-components-sp-menu___en"
  }, {
    path: "/fra/goods/components/magnifier",
    component: _464dc284,
    name: "goods-components-magnifier___fra"
  }, {
    path: "/fra/goods/components/sp-aside-boxes",
    component: _2346e438,
    name: "goods-components-sp-aside-boxes___fra"
  }, {
    path: "/fra/goods/components/sp-brand",
    component: _016729f2,
    name: "goods-components-sp-brand___fra"
  }, {
    path: "/fra/goods/components/sp-bread",
    component: _86935010,
    name: "goods-components-sp-bread___fra"
  }, {
    path: "/fra/goods/components/sp-cart-guess",
    component: _4d828b1a,
    name: "goods-components-sp-cart-guess___fra"
  }, {
    path: "/fra/goods/components/sp-country",
    component: _6db65d96,
    name: "goods-components-sp-country___fra"
  }, {
    path: "/fra/goods/components/sp-head-filter",
    component: _6a0ace85,
    name: "goods-components-sp-head-filter___fra"
  }, {
    path: "/fra/goods/components/sp-list-filter",
    component: _cd7f2df2,
    name: "goods-components-sp-list-filter___fra"
  }, {
    path: "/fra/goods/components/sp-ranking",
    component: _37e08236,
    name: "goods-components-sp-ranking___fra"
  }, {
    path: "/fra/member/components/sp-your-likes",
    component: _5aa5ef34,
    name: "member-components-sp-your-likes___fra"
  }, {
    path: "/fra/ucenter/components/sp-aside",
    component: _7fffec28,
    name: "ucenter-components-sp-aside___fra"
  }, {
    path: "/fra/ucenter/components/sp-menu",
    component: _5008424a,
    name: "ucenter-components-sp-menu___fra"
  }, {
    path: "/",
    component: _7b132fd6,
    name: "index___zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
