//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getFriendLink } from '@/api/index'
export default {
  name: 'SpFooter',
  data () {
    return {
      friendList: []
    }
  },
  mounted () {
    this.getFriendList()
  },
  methods: {
    // 友情链接
    async getFriendList () {
      getFriendLink (this.$axios).then (res => {
        this.friendList = res.data
      })
    },
  }
}
